import { keyframes } from '@emotion/react'
import { Helmet } from 'react-helmet'
import { Grid, Image } from 'theme-ui'

import logoDark from '../images/logoDark.svg'

const fadeIn = keyframes({ from: { opacity: 0 }, to: { opacity: 1 } })

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Privy</title>
      </Helmet>

      <Grid
        as="main"
        sx={{
          width: '100vw',
          height: '100vh',
          placeContent: 'center',
          placeItems: 'center',
          m: 0,
          p: 0,
          gap: 0,
        }}
      >
        <Image
          src={logoDark}
          alt="Privy"
          sx={{
            animation: `${fadeIn} 1s backwards`,
            animationDelay: '300ms',
            pointerEvents: 'none',
            userSelect: 'none',
          }}
        />
      </Grid>
    </>
  )
}

export default IndexPage
